<template>
  <div>
    <b-sidebar
      id="sidebar-create"
      shadow
      right
      bg-variant="white"
      backdrop
      ref="sidebar-create"
      title="Cadastrar novo módulo"
    >
      <div>
         <!-- <h4 class="text-center">Cadastrar novo módulo</h4> -->
         <hr>
         <b-overlay :show="isLoadingStore" opacity="0.70" rounded="sm">
         <b-form enctype="multipart/form-data" method="POST">
         <validation-observer ref="simpleRules">
           <b-form-group
            label="Titulo:"
            label-for="i-nome"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="titulo"
              rules="required"
            >
              <b-form-input
                id="i-nome"
                placeholder="Ex: Imaginar"
                v-model="dataModules.name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-input class="hidden" name="_token" :value="csrf" />
          <b-form-group
            label="Icone:"
            label-for="i-icone"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="icone"
              rules="required"
            >
              <b-form-file
                placeholder="Informe o Icone"
                label="Icone"
                v-model="icon"
                type="file"
                accept="image/*"
                name="icon"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Cor:"
            label-for="i-cor"
            class="pl-1 pr-1"
          >
            <input 
              type="color" 
              id="head" 
              name="head" 
              class="form-control"
              v-model="dataModules.color"
            />
          </b-form-group>
          <b-row style="margin-right: 0rem !important;">
            <b-col  lg="5" sm="6" class="ml-1">
              <b-button variant="relief-primary" @click="storeModules()" block :disabled="dataModules.name == null">
                Salvar
              </b-button>
            </b-col>
            <b-col>
              <b-button 
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                @click="closeSidebar"
                block
              >
                Fechar
              </b-button>
            </b-col>
          </b-row>
          </validation-observer>
         </b-form>
         </b-overlay>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
/* eslint-disable */
import { BButton, BSidebar, VBToggle} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email, confirmed, password,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BButton,
    BSidebar,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      icon: null,
      dataModules: {
        name: null,
        color: '#ffffff',
      },
      iconUpdate: null,
      updateModule: {
        name: null,
        color: null,
        id: null
      },

      isLoadingStore: false,
      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
    }
  },
  computed: {
    ...mapState("modules", ["modules"]),
  },
  methods: {
    ...mapActions("modules", ["store"]),
     storeModules(value) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isLoadingStore = true
          this.store({
            ...this.dataModules,
            icon: this.icon,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Cadastrado com sucesso!',
                icon: 'ThumbsUpIcon',
                text: 'Operação executada com sucesso.',
                variant: 'success'
              },
            })
            this.dataModules.name =  null
            this.dataModules.color = '#ffffff'
            this.icon = null
            this.$nextTick(() => this.$refs.simpleRules.reset());
            this.closeSidebar();
          })
          .finally(() =>{
            this.isLoadingStore = false
          });
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })
       
    },
    closeSidebar(){
      this.$refs['sidebar-create'].hide()
    }
  },
   created() {
    if (! this.csrf) {
      console.warn('The CSRF token is missing. Ensure that the HTML header includes the following: <meta name="csrf-token" content="{{ csrf_token() }}">');
    }
  },
}
</script>