<template>
  <b-card>
      <b-row v-show="modules.length > 0 || search != null">
        <b-col md="7">
            <b-row>
              <b-col md="4">
                <b-form-group
                  label="Exibir"
                  label-for="h-exibir"
                  label-cols-md="4"
                  class="text-muted"
                >
                  <b-form-select
                    size="sm"
                    class="form-content"
                    v-model="perPage"
                    :options="perPageOptions"
                    :disabled="isLoading"
                  />
                </b-form-group>
              </b-col>
            </b-row>
        </b-col>
        <b-col md="3">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Pesquisar"
               v-model="search"
              debounce="800"
              type="search"
              size="sm"
              :disabled="isLoading"
            />
          </b-input-group>
        </b-col>
        <b-col md="2">
          <b-button block variant="relief-primary" v-b-toggle.sidebar-create size="sm" @click='addNew'>
            Novo Módulo
            <feather-icon icon="LayersIcon" />
          </b-button>
        </b-col>
      </b-row>
      <div v-show="modules.length > 0 || search != null">
        <b-overlay :show="isLoading" opacity="0.70" variant="transparent" rounded="sm">
        <b-table-simple
          striped
          responsive
          class="mt-2"
          id="tablePhases"
        >
          <b-thead>
            <b-tr class="text-center">
              <b-th>Nome</b-th>
              <b-th>Cor</b-th>
              <b-th>Data de cadastro</b-th>
              <b-th>Ações</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr :key="indextr" v-for="(tr, indextr) in modules">
              <b-td>
                <b-avatar
                  :src="tr.icon"
                />
                {{tr.name}}
              </b-td>
              <b-td class="text-center">
                <input 
                  type="color" 
                  id="head" 
                  name="head" 
                  :value="tr.color"
                  disabled
                />
              </b-td>
              <b-td class="text-center">
                {{tr.created_at | dateFormat}}
              </b-td>
              <b-td class="text-center">
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-dark"
                  class="btn-icon rounded-circle"
                  v-b-toggle.sidebar-update
                  @click="updateDetail(tr.id)"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-dark"
                  class="btn-icon rounded-circle"
                  v-b-modal.modal-delete
                  @click="showDetail(tr.id)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
          
        </b-table-simple>
        <div class="row" v-show="totalRows > 10">
          <div class="col-6 col-md-9"> 
            Exibindo {{ perPage }} de {{ totalRows }} registros
          </div>
          <div class="col-6 col-md">
            <b-pagination
              v-model="currentPage"
              class="text-center float-right"
              :per-page="perPage"
              :total-rows="totalRows"
              aria-controls="tableSchools"
              size="sm"
              @change="handlePageChange"
            />
          </div>
        </div>
      </b-overlay>
    </div>
    <div class="text-center" v-show="modules.length == 0 && search == null">
      <b-overlay :show="isLoading" opacity="0.70" variant="transparent" rounded="sm">
        <no-data />
        <b-button variant="relief-primary" v-b-toggle.sidebar-create size="sm" @click='addNew'>
          Novo Módulo
          <feather-icon icon="LayersIcon" />
        </b-button>
      </b-overlay>
    </div>
    <create-sidebar :key="count" />
      
    <b-modal
      id="modal-delete"
      ok-variant="danger"
      modal-class="modal-danger"  
      ref="modal-delete"
      centered
      title="Deletar"
      v-if="moduleModel != null"
    >
      <b-card-text>
        <p>Você está preste a deletar este módulo do sistema: <b>{{moduleModel.name}}</b></p>
        Por favor, confirme a exclusão antes de prosseguir.
        <b-form-checkbox
          id="checkbox-1"
          @change="newDeleteStatus"
          v-model="DeleteStatus"
          name="checkbox-1"
          value="accepted"
        >
          Concordo em excluir este módulo
        </b-form-checkbox>  
      </b-card-text>
      <template #modal-footer>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-2"
            variant="outline-danger"
            block
            :disabled="DeleteStatus == false"
            @click="deleteModule(moduleModel.id)"
          >
            Deletar
          </b-button>
        </b-col>
      </template>
    </b-modal>
    <b-sidebar
      id="sidebar-update"
      shadow
      right
      bg-variant="white"
      backdrop
      ref="sidebar-update"
      title="Editar módulo"
    >
      <div>
         <hr>
          <b-form enctype="multipart/form-data" method="POST">
            <validation-observer ref="simpleRules">
            <b-form-group
              label="Titulo:"
              label-for="i-nome"
              class="pl-1 pr-1"
            >
              <validation-provider
                #default="{ errors }"
                name="titulo"
                rules="required"
              >
                <b-form-input
                  id="i-nome"
                  placeholder="Ex: Imaginar"
                  v-model="updateModule.name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-input class="hidden" name="_token" :value="csrf" />
            <b-form-group
              label="Icone:"
              label-for="i-icone"
              class="pl-1 pr-1"
            >
              <b-form-file
                placeholder="Informe o Icone"
                label="Icone"
                v-model="iconUpdate"
                type="file"
                accept="image/*"
                name="icon"
              />
            </b-form-group>
            <b-form-group
              label="Cor:"
              label-for="i-cor"
              class="pl-1 pr-1"
            >
              <input 
                type="color" 
                id="head" 
                name="head" 
                class="form-control"
                v-model="updateModule.color"
              />
            </b-form-group>
            <b-row class="p-1">
              
                <b-button 
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="updateModules(value = 1)"
                  block
                  class="ml-1 mr-1"
                >
                  Atualizar
                </b-button>
            </b-row>
            </validation-observer>
          </b-form>
      </div>
    </b-sidebar>
  </b-card>
</template>
<script>
/* eslint-disable */
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CreateSidebar from "./CreateSidebar.vue";
import {
  required, email, confirmed, password,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import noData from "@/components/systemWarning/NoData.vue";

export default {
  components: {
    BCard,
    CreateSidebar,
    ValidationProvider,
    ValidationObserver,
    noData
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      count: 1,
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,
      isLoading: true,
      DeleteStatus: null,
      moduleModel: null,

      iconUpdate: null,
      updateModule: {
        name: null,
        color: null,
        id: null
      },

      perPageOptions: [
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 20, text: "20" },
      ],

      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
    };
  },
  computed: {
    ...mapState("modules", ["modules"]),
  },
  methods: {
    ...mapActions("modules", ["update"]),
    
    addNew() {
      this.count++;
    },

    fetchModules(paginated = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("modules/fetchModules", {
          paginate: paginated,
          page: page,
          generic: this.search,
          per_page: this.perPage,
        })
        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    newDeleteStatus(value){
      this.DeleteStatus = value;
    },

    handlePageChange(value) {
       this.fetchModules(true, value);
    },

    deleteModule(id) { 
      this.isLoading = true;
      let data = id; 
      this.$store.dispatch("modules/delete", data)
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Excluído com sucesso!',
            icon: 'Trash2Icon',
            text: 'Operação executada com sucesso.',
            variant: 'danger'
          },
        })
        this.isLoading = false;
        this.DeleteStatus = null;  
      })
      .catch(err => { 
        console.error(err); 
      })
      .finally(() =>{
        this.fetchModules()
        this.$refs['modal-delete'].hide()
      });
    },

    updateModules(value) {
      this.$refs.simpleRules.validate().then(success => {
        if(success){
          this.update({
            ...this.updateModule,
            icon: this.iconUpdate,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Atualizado com sucesso!',
                icon: 'ThumbsUpIcon',
                text: 'Operação executada com sucesso.',
                variant: 'success'
              },
            }) 
            this.$nextTick(() => this.$refs.simpleRules.reset());
          })
          .finally(() =>{
            if(value == 1){
              this.$refs['sidebar-update'].hide()
            }
            this.fetchModules()
          });
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })
        
    },

    showDetail(id){
      this.moduleModel = this.$store.getters['modules/getModulesById'](id)
    },

    updateDetail(id){
      this.updateModel = this.$store.getters['modules/getModulesById'](id)
      this.updateModule.name = this.updateModel.name
      this.updateModule.color = this.updateModel.color
      this.updateModule.id = this.updateModel.id
    },
  },
  created() {
     this.fetchModules();
  },
  watch: {
    perPage(val){
        this.fetchModules();
    },
    search(){
      this.currentPage = 1;
      this.fetchModules(true, 1);
    }
  },
};
</script>
<style lang="scss">
  .form-content{
    width: 5rem;

  }
  .form-content-search{
    width: 11rem;
  }
</style>